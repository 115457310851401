var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseCard',{attrs:{"caption-visible":false,"footer-visible":false,"actions-visible":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"h3 font-weight-bold mb-0 text-uppercase"},[_vm._v("proveedores")])]},proxy:true},{key:"actions",fn:function(){return [_c('b-link',{directives:[{name:"access",rawName:"v-access",value:({
        resource: _vm.$data.$constants.RESOURCES.RESOURCE_PROVIDERS,
        resourceAction: _vm.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
      }),expression:"{\n        resource: $data.$constants.RESOURCES.RESOURCE_PROVIDERS,\n        resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL\n      }"}],attrs:{"to":{ name: 'createProvider' }}},[_c('b-button',{staticClass:"text-nowrap rounded-sm px-2",attrs:{"variant":"primary"}},[_vm._v(" + Añadir proveedor ")])],1)]},proxy:true}])},[_c('BaseTable',{ref:"providers-table",attrs:{"resource":_vm.$data.$constants.RESOURCES.RESOURCE_PROVIDERS,"resourceStore":'providers',"columns":_vm.columns,"filters":_vm.filters,"hide-totals":""},on:{"filter-button-click":function($event){_vm.filtersVisible = !_vm.filtersVisible},"on-clear-active-filters":_vm.handleClearListFilters,"row-clicked":function($event){return _vm.$router.push({ name: 'viewProvider', params: { id: $event.id } })}},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('span',{staticClass:"text-gray-50 font-weight-light"},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}},{key:"cell(last_order_date)",fn:function(ref){
      var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")])]}},{key:"cell(province)",fn:function(ref){
      var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(value.name)+" ")])]}},{key:"cell(country)",fn:function(ref){
      var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(value.name)+" ")])]}},{key:"cell(status)",fn:function(ref){
      var value = ref.value;
return [_c('StatusBadge',{attrs:{"status":value.name}})]}},{key:"cell(actions)",fn:function(ref){
      var item = ref.item;
return [_c('span',[_c('b-link',{staticClass:"pr-1 d-inline-block text-indigo",attrs:{"to":{ name: 'viewProvider', params: { id: item.id} }}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Ver","icon":"EyeIcon","size":"18"}})],1),_c('b-link',{directives:[{name:"access",rawName:"v-access",value:({
            resource: _vm.$data.$constants.RESOURCES.RESOURCE_PROVIDERS,
            resourceAction: _vm.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
          }),expression:"{\n            resource: $data.$constants.RESOURCES.RESOURCE_PROVIDERS,\n            resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL\n          }"}],staticClass:"d-inline-block text-danger",on:{"click":function($event){return _vm.deleteProvider(item.id, item.name)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Eliminar","icon":"TrashIcon","size":"18"}})],1)],1)]}}])}),_c('ProviderListFilters',{ref:"provider-list-filters",on:{"filters-submit":function($event){return _vm.$store.commit('providers/setFilters', $event)}},model:{value:(_vm.filtersVisible),callback:function ($$v) {_vm.filtersVisible=$$v},expression:"filtersVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }