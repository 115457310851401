<template>
  <b-form
    @submit.prevent="$emit('submit', model)"
    @keydown.enter="$emit('submit', model)"
  >
    <b-row>
      <b-col sm="12">
        <b-form-group>
          <label>
            Fecha de último pedido entre
          </label>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.date_min"
                class="margin-right-datepicker"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Desde"
                :label-help="null"
                :max="model.date_max"
                @input="update('date_min', $event)"
                start-weekday="1"
              />
            </b-col>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.date_max"
                class="margin-left-datepicker"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Hasta"
                :label-help="null"
                :min="model.date_min"
                @input="update('date_max', $event)"
                start-weekday="1"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Nombre"
          label-for="provider-name"
        >
          <b-form-input
            id="provider-name"
            :value="model.name"
            name="name"
            placeholder="Nombre"
            @input="update('name', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Actividad"
          label-for="provider-province"
        >
          <BaseSelect
            id="provider-activity"
            ref="provider-activity-select"
            :value="model.activity"
            :resource="$data.$constants.RESOURCES.RESOURCE_ACTIVITIES"
            http-method="get"
            @input="update('activity', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="País"
          label-for="provider-country"
        >
          <BaseSelect
            id="provider-country"
            :value="model.country"
            :resource="$data.$constants.RESOURCES.RESOURCE_COUNTRIES"
            http-method="get"
            @input="handleCountryInput"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Provincia"
          label-for="provider-province"
        >
          <BaseSelect
            id="provider-province"
            ref="provider-province-select"
            :value="model.province"
            :resource="$data.$constants.RESOURCES.RESOURCE_PROVINCES"
            @input="update('province', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'ProviderListFiltersForm',
  components: {
    BaseSelect,
  },
  mixins: [FormMixin],
  methods: {
    handleCountryInput(country) {
      this.update('country', country)
      this.$refs['provider-province-select'].loadData({ country })
    },
  },
}
</script>

<style scoped>
.margin-left-datepicker {
  margin-left: 5px;
}
.margin-right-datepicker {
  margin-right: 5px;
}
</style>
