<template>
  <BaseCard
    :caption-visible="false"
    :footer-visible="false"
    actions-visible
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase">proveedores</span>
    </template>
    <template #actions>
      <b-link
        v-access="{
          resource: $data.$constants.RESOURCES.RESOURCE_PROVIDERS,
          resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
        }"
        :to="{ name: 'createProvider' }"
      >
        <b-button
          variant="primary"
          class="text-nowrap rounded-sm px-2"
        >
          + Añadir proveedor
        </b-button>
      </b-link>
    </template>
    <BaseTable
      ref="providers-table"
      :resource="$data.$constants.RESOURCES.RESOURCE_PROVIDERS"
      :resourceStore="'providers'"
      :columns="columns"
      :filters="filters"
      hide-totals
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearListFilters"
      @row-clicked="$router.push({ name: 'viewProvider', params: { id: $event.id } })"
    >
      <template #cell(name)="data">
        <span class="text-gray-50 font-weight-light">
          {{ data.item.name }}
        </span>
      </template>
      <template #cell(last_order_date)="{ value }">
        <span> {{ value | formatDate }} </span>
      </template>
      <template #cell(province)="{ value }">
        <span> {{ value.name }} </span>
      </template>
      <template #cell(country)="{ value }">
        <span> {{ value.name }} </span>
      </template>
      <template #cell(status)="{ value }">
        <StatusBadge :status="value.name" />
      </template>
      <template #cell(actions)="{ item }">
        <span>
          <b-link
            :to="{ name: 'viewProvider', params: { id: item.id} }"
            class="pr-1 d-inline-block text-indigo"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Ver"
              icon="EyeIcon"
              size="18"
            />
          </b-link>
          <b-link
            v-access="{
              resource: $data.$constants.RESOURCES.RESOURCE_PROVIDERS,
              resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
            }"
            class="d-inline-block text-danger"
            @click="deleteProvider(item.id, item.name)"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </b-link>
        </span>
      </template>
    </BaseTable>
    <ProviderListFilters
      ref="provider-list-filters"
      v-model="filtersVisible"
      @filters-submit="$store.commit('providers/setFilters', $event)"
    />
  </BaseCard>
</template>

<script>
import ApiRestService from '@/api/base-api'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import ProviderListFilters from '@/components/providers/filters/ProviderListFilters.vue'
import BaseTable from '@/components/ui/table/BaseTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BaseCard,
    BaseTable,
    ProviderListFilters,
    StatusBadge,
  },
  data() {
    return {
      columns: [
        {
          label: 'FECHA DE ÚLTIMO PEDIDO',
          key: 'last_order_date',
          sortable: true,
        },
        {
          label: 'NOMBRE',
          key: 'name',
          sortable: true,
          thStyle: { 'min-width': '250px' },
        },
        {
          label: 'PROVINCIA',
          key: 'province',
          sortable: true,
        },
        {
          label: 'PAÍS',
          key: 'country',
          sortable: true,
        },
        {
          label: 'ESTADO',
          key: 'status',
          sortable: true,
        },
        {
          label: 'ACCIONES',
          key: 'actions',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { 'min-width': '200px' },
        },
      ],
      filtersVisible: false,
    }
  },
  computed: {
  ...mapGetters({
      filters: 'providers/getFilters',
    }),
  },
  methods: {
    handleClearListFilters() {
      this.$store.commit('providers/setFilters', {})
      this.$refs['provider-list-filters'].clearFilters()
    },
    async deleteProvider(providerId, name) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro ${name}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      await ApiRestService.delete(providerId, this.$data.$constants.RESOURCES.RESOURCE_PROVIDERS)
      await this.$refs['providers-table'].loadData()
    },
  },
}
</script>

<style scoped>
</style>
